@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.public-layout, .admin-layout {

.Loader-container {
  position: relative;
  min-height: 100px;
}

.LoaderContent {
  animation: none;
}

.Loader {
  display: block;
  vertical-align: middle;
  margin: 0px auto;
  margin-top: 0rem;
  margin-bottom: 0rem;
  margin-left: 0rem;
  font-size: 0;
  position: relative;
  border-top: 7px solid color($blue a(30%));
  border-right: 7px solid color($blue a(30%));
  border-bottom: 7px solid color($blue a(30%));
  border-left: 7px solid $blue;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
  z-index: 1000;
}

.Loader--white {
  border-top: 7px solid color(white a(30%));
  border-right: 7px solid color(white a(30%));
  border-bottom: 7px solid color(white a(30%));
  border-left: 7px solid white;
}

.Loader-screen {
  /* z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.5);
  padding: 1rem; */
  opacity: 0.5;
}

.Loader--overlay {
  position: absolute;
  top: 0%;
  right: 0%;
  padding: 0;
 }

.Loader-container--screen {
  .Loader--overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
  }
}

.Loader-container--padded {
  padding: 1rem;
  min-height: unset !important;
}

.Loader,
.Loader:after {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.Loader--padded {
  margin: 1rem;
}

.Loader--center {
  margin: 0 auto;
}

.Loader--small {
  border-top: 5px solid color($blue a(30%));
  border-right: 5px solid color($blue a(30%));
  border-bottom: 5px solid color($blue a(30%));
  border-left: 5px solid $blue;
  vertical-align: top;
  margin-top: -1px;
}

.Loader--small.Loader--white {
  border-top: 5px solid color(white a(30%));
  border-right: 5px solid color(white a(30%));
  border-bottom: 5px solid color(white a(30%));
  border-left: 5px solid white;
  vertical-align: top;
  margin-top: -1px;
}

.Loader--small,
.Loader--small:after {
  width: 25px;
  height: 25px;
}

}