.public-layout, .admin-layout {

.MainContent {
  min-height: 100vh;
  position: relative;
}

.PaddedSection {
  padding: 1.5rem 1rem;

  @mixin above $small {
    padding: 2rem 3rem;
  }
}

.PaddedSection--morePadding {
  padding: 2rem 1rem;

  @mixin above $small {
    padding: 4rem 3rem;
  }
}

.CenteredSection {
  @mixin above $small {
    top: 61px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
  }
}

.CenteredSection-inner {
  background: white;
  border-radius: $radius;
  padding: 1rem;
  box-shadow: 0 0 20px rgba(0,0,0,0.05);

  @mixin above $small {
    width: 31rem;
  }
}

.CenteredSection-inner--noBackground {
  background: none;
  border-radius: 0;
  padding: 1rem 0;
  box-shadow: none;
}

.SecondaryHeader {
  @mixin gordita-medium;
  align-items: center;
  background-color: $lightGrey;
  border: 1px solid $mediumGrey;
  border-width: 1px 0;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: calc($nav-height - 2px);
  z-index: 11;

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 1rem;

    li:first-child {
      margin-left: 0;
    }
  }

  @mixin above $small {
    ul {
      padding: 1rem 3rem;
    }
  }

  @mixin above $medium {
    height: $nav-height;

    ul {
      padding: 1rem 0;
    }
  }
}

.AccountTooltip-inner {
  padding: .25rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;

  a {
    @mixin gordita-medium;
    display: block;
    padding: .5rem;
    color: black;

    &:hover {
      color: $otherGrey;
    }
  }
}

}