/* ----------------------------- */
/* Visibility Utility Classes    */
/* ----------------------------- */

.u-visible {
  display: block !important;
}

.u-noMobile {
  display: none !important;

  @mixin above $medium {
    display: block !important;
  }
}

.u-noMobileInlineBlock {
  display: none !important;

  @mixin above $medium {
    display: inline-block !important;
  }
}

.u-noMobileFlex {
  display: none !important;

  @mixin above $medium {
    display: flex !important;
  }
}

.u-noMobileInlineFlex {
  display: none !important;

  @mixin above $medium {
    display: inline-flex !important;
  }
}

.u-mobileOnly {
  display: block !important;

  @mixin above $medium {
    display: none !important;
  }
}

.u-mobileOnlyFlex {
  display: flex !important;

  @mixin above $medium {
    display: none !important;
  }
}

.u-flexMobileBlock {
  display: block !important;

  @mixin above $medium {
    display: flex !important;
  }
}

.u-position-relative {
  position: relative;
}