@import 'shared.postcss';

/* Base */
@import 'public/base.postcss';

/* Layouts */
@import 'public/layouts/sections.postcss';

/* Content */
@import 'public/content/typography.postcss';

/* Components */
/* @import 'public/components/advertisement.postcss'; */
@import 'public/components/buttons.postcss';
@import 'public/components/forms.postcss';
@import 'public/components/geosuggest.postcss';
/* @import 'public/components/instagram.postcss'; */
@import 'public/components/react-select.postcss';
