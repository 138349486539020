.public-layout {

.MainContent {
  padding-top: $nav-height;

  @mixin above $medium {
    padding-top: $nav-height;
  }
}

}