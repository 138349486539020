.public-layout {

/* -------------------------------------- */
/* Button and Link Modules                */
/* -------------------------------------- */

/* Button ----------------------- */

.Button {
  @mixin gordita-medium;
  background: $blue;
  border: 0;
  border-radius: 4px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-flex;
  font-size: 0.875rem;
  font-weight: bold;
  height: 3rem;
  line-height: 1rem;
  margin-bottom: 1rem;
  padding: 1.1rem 1.25rem 0.75rem;
  position: relative;
  transition: background $hover-speed;
  margin-bottom: 0;

  .Button + &  {
    margin-left: 1rem;
  }

  @mixin above $small {
    &:hover {
      background-color: $hoverBlue;
      color: white;
    }
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }

  &.PlainButton {
    background: none;
    color: $black;
    padding-right: 0;
    padding-left: .5rem;

    &:hover {
      color: $mediumDarkGrey;
    }
  }
}

.Button .icon {
  vertical-align: middle;
  margin-right: 0.4rem;
  font-size: 1em;

  &.icon-close {
    position: relative;
    top: 0.1em;
  }
}

.Button--outlined {
  background: none;
  border: 1px solid $otherGrey;
  border-radius: 4px;
  color: $darkGrey;
  cursor: pointer;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1;
  padding: 1rem 1.25rem 0.75rem;
  transition: background $hover-speed, color $hover-speed;
  vertical-align: middle;

  @mixin above $small {
    &:hover {
      background-color: $grey;
      color: $darkGrey;
    }

    &:before {
      border-color: transparent;
    }
  }
}

.Button--small {
  height: 2.25rem;
  padding: 0.65rem 1rem;
}

.Button--small.Button--outlined {
  padding: 0.65rem 1rem;
}

.LinkButton {
  @mixin gordita-medium;
  background: none;
  border: none;
  color: $blue;
  border-bottom: 1px solid color($blue a(20%));
  cursor: pointer;
  padding: 0;
  display: inline-block;
  line-height: 1;
  font-size: 0.85rem;
  transition: color $hover-speed;

  &:active, &:focus {
    outline: none;
  }

  @mixin above $small {
    &:hover {
      color: $black;
    }
  }
}

.ButtonSet {
  background-color: $lightGrey;
  border: 1px solid $blue;
  border-left-width: 0;
  border-radius: 0;
  color: $darkGrey;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 13px;
  padding: 0.75rem 1.25rem;
  white-space: nowrap;

  &:disabled {
    background-color: $blue;
    color: white;
    cursor: default;
  }

  &:first-child {
    border-left-width: 1px;
  }

  &:not(:last-child) {
    border-right-width: 1px;
  }
}

.ButtonSet--small {
  padding: 0.5rem 1rem;
}

.ButtonSet--left {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ButtonSet--right {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

}