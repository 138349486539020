.public-layout {

.Select-menu-outer {
  border: 1px solid $mediumDarkGrey;
}

.Select-menu-outer .Select-menu {
  max-height: 105px;

  @mixin above $medium {
    max-height: 200px;
  }
}

.Select-control {
  border-color: $mediumDarkGrey;
  height: 3rem;
}

.Select-option {
  padding: 7px 10px;

  &.is-focused {
    background-color: color($blue a(20%));
  }

  &.is-selected {
    background-color: color($blue a(10%));
  }
}

.Select-value {
  padding: 0.5em 0.75em;
}

.Select-placeholder {
  color: $otherGrey;
  padding: 0.5em 0.75em;
}

.Select-placeholder, .Select--single > .Select-control .Select-value {
  max-width: 86%;
}

}