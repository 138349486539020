.public-layout {

h1, h2, h3, h4, h5 {
  @mixin phosphate;
  color: $black;
  margin: 0;
  margin-bottom: $global-padding;
  position: relative;
}

h1 {
  font-size: 2.7rem;
  line-height: 1;
  letter-spacing: 1px;

  @mixin above $medium {
    font-size: 3rem;
  }
}

h2 {
  font-size: 2rem;
  line-height: 1;
  letter-spacing: 1px;
}

h3 {
  font-size: 1.5rem;
  line-height: 1.1;
  letter-spacing: 1.357px;
}

h4 {
  font-size: 1.1875rem;
  margin-bottom: 0.5rem;
  line-height: $base-line-height;
  letter-spacing: 1.35714px;
}

h5 {
  font-size: .825rem;
  line-height: 1;
  text-transform: uppercase;
  strong {
    @mixin phosphate;
  }
}

h6 {
  @mixin gordita-medium;
  font-size: 1rem;
  line-height: 1;
  margin: 0.5rem 0;
}

a {
  color: $blue;
  transition: none;
}

.u-text-sm {
  font-size: 80%;
  line-height: 1.5em;
}

}