/* ------------------------------------------- */
/* JavaScript CSS                              */
/* Classes applied and managed by JavaScripts  */
/* ------------------------------------------- */

.wf-loading {
  visibility: hidden;
}

.wf-active {
  visibility: visible;
}

.wf-inactive {
  visibility: visible;
}
