@font-face {
  font-family: "icons";
  src: url('/static/fonts/icons.eot');
  src: url('/static/fonts/icons.eot?#iefix') format('eot'),
    url('/static/fonts/icons.woff') format('woff'),
    url('/static/fonts/icons.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.public-layout, .admin-layout {

.icon:before {
  display: inline-block;
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* .icon-account:before { content: "\EA01" } */
.icon-approved:before { content: "\EA02" }
.icon-arrow-down:before { content: "\EA03" }
.icon-arrow-left:before { content: "\EA04" }
.icon-arrow-right:before { content: "\EA05" }
.icon-arrow-up:before { content: "\EA06" }
/* .icon-bell:before { content: "\EA07" } */
.icon-calendar:before {
  content: url(/static/images/calendar.svg);
  line-height: unset;
}
.icon-check:before { content: "\EA09" }
.icon-clock:before {
  content: url(/static/images/clock.svg);
  line-height: unset;
}
.icon-close:before { content: "\EA0B" }
/* .icon-comments:before { content: "\EA0C" } */
/* .icon-cutlery:before { content: "\EA0D" } */
.icon-denied:before { content: "\EA0E" }
.icon-facebook:before { content: "\EA0F" }
/* .icon-filters:before { content: "\EA10" } */
/* .icon-google:before { content: "\EA11" } */
/* .icon-heart:before { content: "\EA12" } */
/* .icon-hidden:before { content: "\EA13" } */
/* .icon-hide:before { content: "\EA14" } */
.icon-instagram:before { content: "\EA15" }
/* .icon-list:before { content: "\EA16" } */
/* .icon-lock:before { content: "\EA17" } */
/* .icon-map:before { content: "\EA18" } */
/* .icon-options:before { content: "\EA19" } */
.icon-paperclip:before { content: "\EA1A" }
/* .icon-pending:before { content: "\EA1B" } */
/* .icon-people:before { content: "\EA1C" } */
.icon-pin:before { content: url(/static/images/pin.svg) }
/* .icon-signs:before { content: "\EA1E" } */
.icon-star:before { content: "\EA1F" }
/* .icon-target:before { content: "\EA20" } */
/* .icon-thumbs-up:before { content: "\EA21" } */
.icon-trash:before { content: "\EA22" }
/* .icon-truck-2:before { content: "\EA23" } */
/* .icon-truck:before { content: "\EA24" } */
.icon-twitter:before { content: "\EA25" }
.icon-warning:before { content: "\EA26" }
.icon-yelp:before { content: "\EA27" }

}