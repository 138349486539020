/* Vendor */
@import '../../node_modules/normalize.css/normalize.css';
@import '../../node_modules/react-select/dist/react-select.css';

/* Variables */
@import 'variables.postcss';

/* Fonts */
@import 'shared/content/fonts.postcss';
@import 'shared/content/icons.postcss';

/* Utils */
@import 'utils/mixins.postcss';
@import 'utils/grid.postcss';
@import 'utils/positioning.postcss';
@import 'utils/spacing.postcss';
@import 'utils/sizing.postcss';
@import 'utils/visibility.postcss';

/* JS Specific */
@import 'utils/js.postcss';

/* Base HTML Elements */
@import 'base.postcss';

/* Utils that depend on Base */
@import 'utils/type.postcss';

/* Layouts */
@import 'shared/layouts/sections.postcss';

/* Components */
@import 'shared/components/box.postcss';
@import 'shared/components/container.postcss';
@import 'shared/components/loader.postcss';
@import 'shared/components/pagination.postcss';
@import 'shared/components/search.postcss';
