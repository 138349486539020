@import '../../../stylesheets/variables.postcss';

.ModalContainer {
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  overflow-y: auto;
}

.ModalContent {
  max-width: 400px;
  min-width: 300px;
  background: white;
  border-radius: $radius;
  padding: 1rem;
  max-height: calc(100vh - 20px);
  overflow-y: auto;
}

.ModalContent__center {
  text-align: center;
}

.ModalContent__morePadding {
  max-width: 430px;
  padding: 2rem;
}
