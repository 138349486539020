.public-layout {

.geosuggest {
  position: relative;
  width: 100%;
}

.geosuggest__input {
  @mixin rounded-input;
  height: 3rem;
  width: 100%;
}

.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border: 1px solid;
  overflow-x: hidden;
  border-color: #CCC9C1 $blue $blue $blue !important;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  border-radius: 0 0 $radius $radius;
  margin-top: -4px;
}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

.geosuggest__item {
  color: $black;
  border-color: #b7b7b5;
  font-size: 0.75rem;
  margin: 0;
  padding: 0.5rem;
  cursor: pointer;
  z-index: 5;
  position: relative;
}

.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5;
}
.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}

}