/* ----------------------- */
/* Sizing Utility Classes  */
/* ----------------------- */

/* Width Limiters */

$increments: (20, 25, 30, 35, 50, 70);

@each $value in $increments {
  .u-limitWidth$(value) {
    width: 100%;
    max-width: $(value)rem !important;
  }
}

.u-widthAuto {
  width: auto !important;
}

.u-w100 {
  width: 100% !important;
}

.u-h100 {
  height: 100%;
}

.u-minHeight500 {
  min-height: 500px;
}
