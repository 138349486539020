.public-layout {

/* FlexForm ----------------------- */

.FlexForm .Fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  display: flex;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  width: auto;
  min-width: 0;
  padding-bottom: 0rem;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
}

.FlexForm hr {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-top: 0.5rem;
}

/* FormRow ----------------------- */

.FormRow {
  flex: 1 0 100%;
  margin-bottom: 1rem;
  padding: 0 0.75rem;
  position: relative;

  .Button {
    margin-bottom: 0;
  }
}

/* Modifiers */

.FormRow--half {
  @mixin above $small {
    flex: 0 0 50%;
  }
}

.FormRow--third {
  @mixin above $small {
    flex: 0 0 33.3333333333%;
  }
}

/* FormLabel ----------------------- */

.FormLabel {
  margin-bottom: 0.25rem;

  abbr[title=required] {
    text-decoration: none;
    color: $orange;
  }

  @mixin above $small {
    font-size: 1rem;
  }
}

.FormLabel--required {
  &:after {
    content: " *";
    color: $orange;
  }
}
.FormLabel--disabled {
  opacity: 0.4;
}

.FormLabel--bold {
  font-weight: 800;
}

.FormLabel--small {
  font-size: 0.75rem;
}


/* TextInput ----------------------- */

.TextInput {
  @mixin rounded-input;
  height: 3rem;
  width: 100%;
  border-color: $mediumDarkGrey;
}


/* TextArea ----------------------- */

.TextArea {
  @mixin rounded-input;
  width: 100%;
  line-height: 1.6;
  min-height: 5rem;
  border-color: $mediumDarkGrey;
}


/* Checkbox ----------------------- */

.Checkbox {
  font-size: 0.875rem;

  input[type=checkbox] {
    display: inline-block;
    width: auto;
    margin-right: 0.5rem;
  }
}

/* RadioGroup ----------------------- */

.RadioGroup {
  label {
    display: inline-block;
    margin-right: 1rem;
    font-size: 0.875rem;

    span {
      display: inline-block;
      margin-left: 0.4rem;
    }
  }

  &--custom {
    input {
      margin-right: 0.25rem;
    }
  }
}


/* FileField ----------------------- */

.FileField {
  position: relative;
  label {
    margin-bottom: 0.25rem;
    transition: color $hover-speed;
  }
  label:hover {
    color: $orange;
  }
}

.FileField input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.FileField .icon-camera {
  font-size: 1.65rem;
  cursor: pointer;
}


/* ErrorExplanation ----------------------- */

.ErrorExplanation, .input .hint,
.input .error, .FormRow .hint, .FileField .hint {
  margin-top: 0.4rem;
  font-size: 0.8rem;
  line-height: 1.3;
  display: block;

  @mixin above $small {
    font-size: 0.8rem;
  }
}

.hint {
  display: inline-block;
  margin-right: 0.5rem;
  line-height: 1.2;
}

.ErrorExplanation, .input .error {
  @mixin gordita-medium;
  color: $red;
}


/* FormErrors ----------------------- */

.FormMessages {
  @mixin gordita-bold;
  position: relative;
  background: $blue;
  flex: 1 1 auto;
  color: white;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  padding-left: 4rem;
  vertical-align: middle;

  .icon {
    opacity: 0.3;
    display: block;
    position: absolute;
    left: 1.5rem;
    top: 50%;
    margin-top: 0.1em;
    font-size: 1.5rem;
    transform: translateY(-50%);
  }

  h4 {
    width: 100%;
    color: white
  }

  a {
    display: inline-block;
    color: white;
    border-bottom: 2px solid rgba(255,255,255,0.25);
  }
}

.FormMessages + .FormMessages {
  margin-top: -1rem;
}

.FormMessages--error {
  background: $blue;
}

.Required {
  @mixin gordita-bold;
  color: $otherGrey;
  font-size: 0.625rem;
  margin-right: 1rem;
  margin-top: -1.25rem;
  position: absolute;
  right: 0;
  top: 6.25rem;
}

}