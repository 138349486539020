/* Display */

@mixin util block {
  display: block !important;
}

@mixin util flex {
  display: flex !important;
}

@mixin util align-center {
  align-items: center !important;
}

@mixin util align-flex-end {
  align-items: flex-end !important;
}

@mixin util flex-column {
  flex-direction: column !important;
}

/* Floats */

@mixin util pullRight {
  float: right !important;
}

@mixin util pullLeft {
  float: left !important;
}


/* Alignment */

@mixin util textCenter {
  text-align: center !important;
}

@mixin util textLeft {
  text-align: left !important;
}

@mixin util textRight {
  text-align: right !important;
}

@mixin util blockCenter {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}