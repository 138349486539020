.public-layout, .admin-layout {

.Pagination {
  margin-top: 0.5rem;
  margin-bottom: -0.5rem;
  text-align: center;
  list-style: none;
  user-select: none;
  font-size: 1.25rem;
  padding: 0.5rem;
  border-radius: $radius;

  @mixin above $medium {
    font-size: 1rem;
  }
}

.Pagination li {
  margin: 0 0.25rem;
  display: inline-block;

  &.Pagination-disabled {
    display: none;
  }
}

li.Pagination-break {
  margin: 0;
}

.Pagination a {
  cursor: pointer;
  &:active, &:focus {
    outline: none;
  }
}

.Pagination-selected {
  a {
    color: $black;
    font-weight: 900;
  }
}

.Pagination-previous, .Pagination-next {
  @mixin gordita-bold;
  font-weight: 500;
}

}