.public-layout, .admin-layout {

.Box {
  background: #efefef;
  padding: 1rem;
  position: relative;
  border-radius: $radius;
}

.Box--white {
  background: white;
}

.Box--grey {
  background: $mediumGrey;
}

}